/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { RouteComponentProps, Router } from '@reach/router';
import { useWeb3React } from '@web3-react/core';
import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
    ICryptoPunks,
    ICryptoPunks__factory,
    IWrappedPunks,
    IWrappedPunks__factory,
    StitchedPunksShop,
    StitchedPunksShop__factory,
} from '../../contracts/artifacts/types';
import Layout from '../components/Layout';
import {
    CRYPTOPUNKS_CONTRACT,
    ICON_EMPTY_COLORED_FILENAME,
    ICON_EMPTY_FILENAME,
    isValidPunkIndex,
    PunkEventSorter,
    STITCHEDPUNKSSHOP_CONTRACT,
    truncAddress,
    WRAPPEDPUNKS_CONTRACT,
} from '../web3/BlockchainUtils';

export const query = graphql`
    {
        imgIcon: allImageSharp {
            edges {
                node {
                    gatsbyImageData(placeholder: BLURRED, width: 100, height: 100, quality: 75)
                    fluid {
                        originalName
                        originalImg
                    }
                }
            }
        }
    }
`;

const SortFunctionNumber = (a: number, b: number): number => a - b;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Profile = ({ walletAddress, data }: { walletAddress?: string; data: any } & RouteComponentProps): JSX.Element => {
    const web3 = useWeb3React();
    const [isLoading, setIsLoading] = useState(true);
    const [isErrorMetaMask, setIsErrorMetaMask] = useState(false);
    const [cryptoPunksList, setCryptoPunksList] = useState<number[]>([]);
    const [wrappedPunksList, setWrappedPunksList] = useState<number[]>([]);
    const [stitchedPunksList, setStitchedPunksList] = useState<number[]>([]);
    const [punkDetailPageToJumpTo, setPunkDetailPageToJumpTo] = useState<number>(-1);

    const fetchPunks = useCallback(async () => {
        if (web3.library === undefined || !web3.active || !web3.account) {
            setIsLoading(false);
            setIsErrorMetaMask(true);
            return;
        }

        try {
            const account = walletAddress !== undefined ? walletAddress.toLowerCase() : web3.account.toLowerCase();
            console.log('account to show:', account);

            setIsLoading(true);
            setIsErrorMetaMask(false);

            const myCryptoPunks: number[] = [];
            const myWrappedPunks: number[] = [];
            const myStitchedPunks: number[] = [];

            const punkEventSorter = new PunkEventSorter();
            const wrappedPunkEventSorter = new PunkEventSorter();
            // const missingPunksPunkTransferEvents: number[] = [];
            // const missingPunksPunkBoughtEvents: number[] = [];

            // common CryptoPunks
            const cpContract: ICryptoPunks = ICryptoPunks__factory.connect(CRYPTOPUNKS_CONTRACT, web3.library);

            const tasks: Promise<void>[] = [];

            tasks.push(
                (async () => {
                    // punks transferred to own address
                    // event PunkTransfer(address indexed from, address indexed to, uint256 punkIndex);
                    const filter1 = cpContract.filters.PunkTransfer(null, account, null);
                    const events1 = await cpContract.queryFilter(filter1, 0, 'latest');

                    events1.forEach((ev) => {
                        console.log('event PunkTransfer:', ev);
                        punkEventSorter.addPunk(ev.args.punkIndex.toNumber(), ev.blockNumber);
                        // missingPunksPunkTransferEvents.push(ev.blockNumber);
                    });
                })(),
            );

            tasks.push(
                (async () => {
                    // punk bought and transferred to own address
                    // event PunkBought(uint indexed punkIndex, uint value, address indexed fromAddress, address indexed toAddress);
                    const filter2 = cpContract.filters.PunkBought(null, null, null, account);
                    const events2 = await cpContract.queryFilter(filter2, 0, 'latest');

                    events2.forEach((ev) => {
                        console.log('event PunkBought:', ev);
                        punkEventSorter.addPunk(ev.args.punkIndex.toNumber(), ev.blockNumber);
                        // missingPunksPunkBoughtEvents.push(ev.blockNumber);
                    });
                })(),
            );

            tasks.push(
                (async () => {
                    // punks transferred from own address to other address
                    // event PunkTransfer(address indexed from, address indexed to, uint256 punkIndex);
                    const filter3 = cpContract.filters.PunkTransfer(account, null, null);
                    const events3 = await cpContract.queryFilter(filter3, 0, 'latest');

                    events3.forEach((ev) => {
                        console.log('event PunkTransfer:', ev);
                        punkEventSorter.removePunk(ev.args.punkIndex.toNumber(), ev.blockNumber);
                    });
                })(),
            );

            tasks.push(
                (async () => {
                    // punk sold and transferred from own address to other address
                    // event PunkBought(uint indexed punkIndex, uint value, address indexed fromAddress, address indexed toAddress);
                    const filter4 = cpContract.filters.PunkBought(null, null, account, null);
                    const events4 = await cpContract.queryFilter(filter4, 0, 'latest');

                    events4.forEach((ev) => {
                        console.log('event PunkBought:', ev);
                        punkEventSorter.removePunk(ev.args.punkIndex.toNumber(), ev.blockNumber);
                    });
                })(),
            );

            tasks.push(
                (async () => {
                    // punk claimed
                    // event Assign(address indexed to, uint256 punkIndex);
                    const filter5 = cpContract.filters.Assign(account, null);
                    const events5 = await cpContract.queryFilter(filter5, 0, 'latest');

                    events5.forEach((ev) => {
                        console.log('event Assign:', ev);
                        punkEventSorter.addPunk(ev.args.punkIndex.toNumber(), ev.blockNumber);
                    });
                })(),
            );

            // WrappedPunks
            const wpContract: IWrappedPunks = IWrappedPunks__factory.connect(WRAPPEDPUNKS_CONTRACT, web3.library);

            tasks.push(
                (async () => {
                    // wrapped punk transferred to own address
                    // event Transfer(address indexed from, address indexed to, uint256 indexed tokenId);
                    const filter6 = wpContract.filters.Transfer(null, account, null);
                    const events6 = await wpContract.queryFilter(filter6, 0, 'latest');

                    events6.forEach((ev) => {
                        console.log('event WrappedPunk: Transfer:', ev);
                        wrappedPunkEventSorter.addPunk(ev.args.tokenId.toNumber(), ev.blockNumber);
                    });
                })(),
            );

            tasks.push(
                (async () => {
                    // wrapped punk transferred from own address to other address
                    // event Transfer(address indexed from, address indexed to, uint256 indexed tokenId);
                    const filter7 = wpContract.filters.Transfer(account, null, null);
                    const events7 = await wpContract.queryFilter(filter7, 0, 'latest');

                    events7.forEach((ev) => {
                        console.log('event WrappedPunk: Transfer:', ev);
                        wrappedPunkEventSorter.removePunk(ev.args.tokenId.toNumber(), ev.blockNumber);
                    });
                })(),
            );

            // fetch all missing punks due to a bug in the PunkBought event
            // (toAddress can be 0x0 if the punk was bought via acceptBidForPunk().. L233 uses bid.bidder which was set to 0x0 in L229)
            // approach:
            // (1) fetch all Transfer events with event.to==account (save blocknumber in missingPunksAllTransferEvents)
            // (2) remove all PunkTransfer events within the same block (save blocknumber in missingPunksPunkTransferEvents)
            // (3) remove all PunkBought events within the same block with toAddress==account (save blocknumber in missingPunksPunkBoughtEvents)
            // (4) everything that is left, should be the buggy PunkBought events after acceptBidForPunk() function calls
            // (5) fetch all PunkBought events for those missing blocks
            // event Transfer(address indexed from, address indexed to, uint256 value);
            // console.log('fetch missing punks');
            // const missingPunksAllTransferEvents: number[] = [];
            // const filter6 = cpContract.filters.Transfer(null, account, null);
            // const events6 = await cpContract.queryFilter(filter6, 0, 'latest');

            // events6.forEach((ev) => {
            //     // (1) fetch all Transfer events with event.to==account (missingPunksAllTransferEvents)
            //     missingPunksAllTransferEvents.push(ev.blockNumber);
            // });

            // (2) remove all PunkTransfer events within the same block (save blocknumber in missingPunksPunkTransferEvents)
            // missingPunksPunkTransferEvents.forEach((bn) => {
            //     missingPunksAllTransferEvents.splice(missingPunksAllTransferEvents.indexOf(bn), 1);
            // });
            // (3) remove all PunkBought events within the same block with toAddress==account (save blocknumber in missingPunksPunkBoughtEvents)
            // missingPunksPunkBoughtEvents.forEach((bn) => {
            //     missingPunksAllTransferEvents.splice(missingPunksAllTransferEvents.indexOf(bn), 1);
            // });
            // (4) everything that is left, should be the buggy PunkBought events after acceptBidForPunk() function calls
            // console.log('result of missing punks in these blocks:', missingPunksPunkBoughtEvents);

            // (5) fetch all PunkBought events for those missing blocks
            // be aware that this is not very clean, as it is possible that PunkBought events were emitted from other accounts in the same block
            // console.log('fetch all PunkBought events for those missing blocks');

            // const filter7 = cpContract.filters.PunkBought(null, null, null, null);
            // for (let i = 0; i < missingPunksPunkBoughtEvents.length; i++) {
            //     const blockNumber = missingPunksPunkBoughtEvents[i];

            //     const eventsForSingleBlock = await cpContract.queryFilter(filter7, blockNumber);
            //     if (eventsForSingleBlock.length !== 0) {
            //         // simply take the first event
            //         const event = eventsForSingleBlock[0];
            //         punkEventSorter.addPunk(event.args.punkIndex.toNumber(), event.blockNumber);
            //     }
            // }

            // wait for all calls to finish
            await Promise.all(tasks);

            // merge events and calculate all punks in the wallet (as events cover adding and removing of punks)
            punkEventSorter.mergeEvents();
            wrappedPunkEventSorter.mergeEvents();
            myCryptoPunks.push(...punkEventSorter.getPunkList(), ...wrappedPunkEventSorter.getPunkList());
            myCryptoPunks.sort(SortFunctionNumber);
            console.log('All punks:', myCryptoPunks);

            console.log('My CryptoPunks:', myCryptoPunks);
            setCryptoPunksList(myCryptoPunks);

            // sort and save WrappedPunks
            myWrappedPunks.push(...wrappedPunkEventSorter.getPunkList());
            myWrappedPunks.sort(SortFunctionNumber);
            setWrappedPunksList(myWrappedPunks);

            // fetch all StitchedPunks

            const shop: StitchedPunksShop = StitchedPunksShop__factory.connect(
                STITCHEDPUNKSSHOP_CONTRACT,
                web3.library,
            );

            // StitchedPunk ordered
            // event OrderCreated(uint16 indexed punkId, address indexed owner);
            const filter8 = shop.filters.OrderCreated(null, account);
            const events8 = await shop.queryFilter(filter8, 0, 'latest');

            events8.forEach((ev) => {
                console.log('event StitchedPunk OrderCreated:', ev);
                myStitchedPunks.push(ev.args.punkId);
            });

            console.log('My StitchedPunk Orders:', myStitchedPunks);
            myStitchedPunks.sort(SortFunctionNumber);
            setStitchedPunksList(myStitchedPunks);

            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            setIsErrorMetaMask(true);
            console.log('error fetching punks:', err);
        }
    }, [walletAddress, web3.account, web3.active, web3.library]);

    useEffect(() => {
        fetchPunks();
    }, [fetchPunks]);

    const PunkList = (punkList: number[]): JSX.Element => {
        const rows: JSX.Element[] = [];

        punkList.forEach((punkIndex) => {
            const paddedId = punkIndex.toString().padStart(4, '0');
            const imgUrl = '/cryptopunks/punk' + paddedId + '.png';
            const isStitchedPunkOrdered = stitchedPunksList.includes(punkIndex);
            const isWrappedPunk = wrappedPunksList.includes(punkIndex);

            // fetch StitchedPunk icon from query with all images
            const imageIcon: JSX.Element[] = [];

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            data.imgIcon.edges.forEach((edge: any) => {
                if (edge.node.fluid.originalName === paddedId + '-icon.jpg') {
                    imageIcon.push(
                        <GatsbyImage
                            image={edge.node.gatsbyImageData}
                            alt="Photo of StitchedPunk"
                            key={'sp-icon-' + punkIndex}
                        />,
                    );
                }
            });

            // if no StitchedPunk icon was found and SP is not ordered yet: add empty placeholder
            if (imageIcon.length === 0 && !isStitchedPunkOrdered) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data.imgIcon.edges.forEach((edge: any) => {
                    if (edge.node.fluid.originalName === ICON_EMPTY_FILENAME) {
                        imageIcon.push(
                            <GatsbyImage
                                className="placeholder-icon"
                                image={edge.node.gatsbyImageData}
                                alt="No StitchedPunk created yet"
                                key={'sp-icon-' + punkIndex}
                            />,
                        );
                    }
                });
            }

            // if no StitchedPunk icon was found but SP is already ordered: add colored placeholder
            if (imageIcon.length === 0 && isStitchedPunkOrdered) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                data.imgIcon.edges.forEach((edge: any) => {
                    if (edge.node.fluid.originalName === ICON_EMPTY_COLORED_FILENAME) {
                        imageIcon.push(
                            <GatsbyImage
                                className="placeholder-icon"
                                image={edge.node.gatsbyImageData}
                                alt="No StitchedPunk created yet"
                                key={'sp-icon-' + punkIndex}
                            />,
                        );
                    }
                });
            }

            rows.push(
                <div className="grid-wrapper punk-card" key={punkIndex}>
                    <div className="col-6 grid-wrapper">
                        <div className="col-12">
                            <h4>CryptoPunk #{punkIndex}</h4>
                        </div>
                        <div className="col-4">
                            <img src={imgUrl} alt={'CryptoPunk #' + punkIndex} className="punk-image pixelated" />
                        </div>
                        <div className="col-8 cryptopunk-details">
                            <ul className="alt">
                                <li>
                                    <a
                                        href={'https://www.larvalabs.com/cryptopunks/details/' + punkIndex}
                                        className="icon fa-external-link">
                                        Show on CryptoPunks Website
                                    </a>
                                </li>
                                {isWrappedPunk && <li>Owned as WrappedPunk</li>}
                            </ul>
                        </div>
                    </div>
                    <div className="col-6 grid-wrapper">
                        <div className="col-12">
                            <h4>StitchedPunk #{punkIndex}</h4>
                        </div>
                        <div className="col-4">{imageIcon}</div>
                        <div className="col-8 stitchedpunks-details">
                            <ul className="alt">
                                <li>
                                    {isStitchedPunkOrdered && (
                                        <>
                                            <span className="ordered">
                                                StitchedPunk ordered <span className="icon fa-check-circle"></span>
                                            </span>
                                        </>
                                    )}
                                    {!isStitchedPunkOrdered && (
                                        <>
                                            StitchedPunk can be ordered <span className="icon fa-shopping-cart"></span>
                                        </>
                                    )}
                                </li>
                                <li className="show-details">
                                    <Link
                                        to={'/details/' + punkIndex}
                                        className="link primary button small icon fa-search">
                                        Show Details
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>,
            );
        });

        return <div>{rows}</div>;
    };

    const isOwnProfile =
        walletAddress === undefined ||
        walletAddress === '' ||
        walletAddress.toLowerCase() === web3.account?.toLowerCase();
    return (
        <Layout>
            <Helmet
                title="StitchedPunks – Profile"
                meta={[{ name: 'description', content: 'StitchedPunks – Profile' }]}
            />

            <div id="profile" className="alt">
                <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h1>Profile</h1>
                        </header>
                        {isOwnProfile && (
                            <div>
                                <p>
                                    This is your profile where all the CryptoPunks and StitchedPunks you own are listed
                                    (both regular CryptoPunks and WrappedPunks). It shows the ownership for your
                                    connected wallet, so feel free to switch between your accounts.
                                </p>
                                <p>
                                    <a
                                        href={
                                            'https://www.larvalabs.com/cryptopunks/accountinfo?account=' +
                                            web3.account?.toLowerCase()
                                        }
                                        className="icon fa-external-link">
                                        Show profile on CryptoPunks Website
                                    </a>
                                </p>
                                <p>Order a StitchedPunk by visiting the detail page for a punk listed below.</p>
                            </div>
                        )}
                        {!isOwnProfile && (
                            <div>
                                <p>
                                    This is the profile page of{' '}
                                    {walletAddress === undefined ? '?' : truncAddress(walletAddress.toLowerCase())}{' '}
                                    where all the CryptoPunks and StitchedPunks owned by this wallet are listed (both
                                    regular CryptoPunks and WrappedPunks).
                                </p>
                                {walletAddress !== undefined && (
                                    <p>
                                        <a
                                            href={
                                                'https://www.larvalabs.com/cryptopunks/accountinfo?account=' +
                                                walletAddress.toLowerCase()
                                            }
                                            className="icon fa-external-link">
                                            Show profile on CryptoPunks Website
                                        </a>
                                    </p>
                                )}
                            </div>
                        )}
                        <hr className="major" />
                        <div>
                            <div>
                                <h3 className="first-heading">
                                    {isLoading || !isOwnProfile ? 'All' : 'My'} StitchedPunks
                                    {!isLoading && <span> ({stitchedPunksList.length})</span>}
                                </h3>
                                {isLoading && (
                                    <p>
                                        <span className="loading-animation">Loading...</span>
                                    </p>
                                )}
                                {!isLoading && isErrorMetaMask && (
                                    <div className="col-12 message-box">
                                        <span className="error-message">
                                            <span role="img" aria-label="exclamation-mark icon">
                                                ⚠️
                                            </span>{' '}
                                            Could not connect to Ethereum. Please reconnect in the menu, check your
                                            MetaMask and try again.
                                        </span>
                                    </div>
                                )}
                                {!isLoading && !isErrorMetaMask && (
                                    <div>
                                        {stitchedPunksList.length > 0 ? (
                                            <div>
                                                {isOwnProfile && (
                                                    <p>
                                                        Check the status of your orders or take a look at the detail
                                                        page:
                                                    </p>
                                                )}
                                                <div>{PunkList(stitchedPunksList)}</div>
                                            </div>
                                        ) : (
                                            <p>
                                                {isOwnProfile ? (
                                                    <i>
                                                        It seems that you don't have any StitchedPunks... Just order one
                                                        for your punks below!
                                                    </i>
                                                ) : (
                                                    <i>It seems that this account has no StitchedPunks yet...</i>
                                                )}
                                            </p>
                                        )}
                                    </div>
                                )}
                                <hr className="major" />
                                <h3 className="second-heading">
                                    {isLoading || !isOwnProfile ? 'All' : 'My'} CryptoPunks and StitchedPunks
                                    {!isLoading && <span> ({cryptoPunksList.length})</span>}
                                </h3>
                                {isLoading && (
                                    <p>
                                        <span className="loading-animation">Loading...</span>
                                    </p>
                                )}
                                {!isLoading && isErrorMetaMask && (
                                    <div className="col-12 message-box">
                                        <span className="error-message">
                                            <span role="img" aria-label="exclamation-mark icon">
                                                ⚠️
                                            </span>{' '}
                                            Could not connect to Ethereum. Please reconnect in the menu, check your
                                            MetaMask and try again.
                                        </span>
                                    </div>
                                )}
                                {!isLoading && !isErrorMetaMask && (
                                    <div>
                                        {cryptoPunksList.length > 0 ? (
                                            PunkList(cryptoPunksList)
                                        ) : (
                                            <p>
                                                {isOwnProfile ? (
                                                    <i>
                                                        It seems that you don't have any CryptoPunks... You can buy one
                                                        at{' '}
                                                        <a href="https://www.larvalabs.com/cryptopunks/forsale">
                                                            the official website
                                                        </a>{' '}
                                                        or{' '}
                                                        <a href="https://opensea.io/collection/cryptopunks">OpenSea</a>!
                                                        !
                                                    </i>
                                                ) : (
                                                    <i>It seems that this account has no CryptoPunks yet...</i>
                                                )}
                                            </p>
                                        )}
                                        <div>
                                            <p>
                                                <i>
                                                    Attention: Some punks might be missing here as not all buy and sell
                                                    events are considered yet. This feature is currently under
                                                    development.
                                                </i>
                                            </p>
                                            <p>
                                                <i>
                                                    In the meantime, just visit the{' '}
                                                    <Link to="/details">detail page</Link> of the desired punk manually.
                                                    Thanks a lot!
                                                </i>
                                            </p>
                                            <ul className="actions">
                                                <li>Enter punk number:</li>
                                                <li>
                                                    <input
                                                        type="text"
                                                        name="punk-page-to-jump-to"
                                                        id="punk-page-to-jump-to"
                                                        defaultValue=""
                                                        placeholder=""
                                                        onChange={(e) =>
                                                            setPunkDetailPageToJumpTo(Number.parseInt(e.target.value))
                                                        }
                                                    />
                                                </li>
                                                <li>
                                                    <Link
                                                        to={
                                                            isValidPunkIndex(punkDetailPageToJumpTo)
                                                                ? '/details/' + punkDetailPageToJumpTo
                                                                : ''
                                                        }
                                                        className="button small icon fa-search">
                                                        Show Details
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ProfileRouter = ({ data }: { data: any }): JSX.Element => {
    return (
        <>
            <Router>
                <Profile path="/profile/:walletAddress" data={data} />
                <Profile path="/profile" data={data} />
            </Router>
        </>
    );
};

export default ProfileRouter;
